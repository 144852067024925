import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';
import avatar from '../assets/images/avatar.png';

const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    background-color: #1A1A1A;
    font-family: 'RetroFont', serif;
    overflow: hidden;
  }

  #root {
    height: 100%;
  }
`;

const About = () => {
    const navigate = useNavigate();

    const handleClose = () => {
        navigate('/');
    };

    return (
        <>
            <GlobalStyle />
            <ScreenContainer>
                <CharacterSheet>
                    <CloseButton onClick={handleClose}>X</CloseButton>
                    <Sidebar>
                        <Avatar src={avatar} alt="Avatar" />
                        <Name>Wilbert Tham</Name>
                        <Role>Web Developer <br /> Level 20</Role>
                        <ContactInfo>
                            <ContactItem>
                                <Label>Email:</Label>
                                <Value>wilbertthxm@gmail.com</Value>
                            </ContactItem>
                            <ContactItem>
                                <Label>Phone:</Label>
                                <Value>+44 7776713712</Value>
                            </ContactItem>
                            <ContactItem>
                                <Label>Location:</Label>
                                <Value>Birmingham, United Kingdom</Value>
                            </ContactItem>
                            <ContactItem>
                                <Label>Socials:</Label>
                                <SocialLinks>
                                    <SocialLink href="https://github.com/phoonicked" target="_blank">
                                        <GithubIcon alt="GitHub" />
                                    </SocialLink>
                                    <SocialLink href="https://www.linkedin.com/in/wilberttham/" target="_blank">
                                        <LinkedinIcon alt="LinkedIn" />
                                    </SocialLink>
                                </SocialLinks>
                            </ContactItem>
                        </ContactInfo>
                    </Sidebar>
                    <MainContent>
                        <Section>
                            <SectionTitle>About</SectionTitle>
                            <AboutText>
                                I am a Full Stack Developer with 2 years of experience, currently pursuing my second year at the University of Birmingham. My expertise spans a broad range of technologies, including MongoDB, Python, Flask, JavaScript, TypeScript, React, PostgreSQL, Firebase, Git, Vue.js, Jenkins, and Buefy. I have a strong foundation in building systems and platforms from scratch with a focus on efficiency and attention to detail.
                            </AboutText>
                        </Section>
                        <Section>
                            <SectionTitle>Skills & Expertise</SectionTitle>
                            <AttributesGrid>
                                <Attribute>
                                    <AttributeName>Frontend</AttributeName>
                                    <AttributeValue>React, Vue.js, TypeScript, JavaScript</AttributeValue>
                                </Attribute>
                                <Attribute>
                                    <AttributeName>Backend</AttributeName>
                                    <AttributeValue>Flask, Node.js, Express</AttributeValue>
                                </Attribute>
                                <Attribute>
                                    <AttributeName>Database</AttributeName>
                                    <AttributeValue>MongoDB, PostgreSQL, Firebase</AttributeValue>
                                </Attribute>
                                <Attribute>
                                    <AttributeName>DevOps</AttributeName>
                                    <AttributeValue>Jenkins, Git, CI/CD, Docker</AttributeValue>
                                </Attribute>
                                <Attribute>
                                    <AttributeName>Design</AttributeName>
                                    <AttributeValue>Figma</AttributeValue>
                                </Attribute>
                            </AttributesGrid>
                        </Section>
                    </MainContent>
                </CharacterSheet>
            </ScreenContainer>
        </>
    );
};

export default About;

const ScreenContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`;

const CharacterSheet = styled.div`
    width: 90%;
    height: 90%;
    background-color: #3E2723;
    border: 4px solid #FF5F1F;
    padding: 20px;
    box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.7), 0 0 40px rgba(0, 0, 0, 0.8);
    color: white;
    text-shadow: 2px 2px #000;
    display: flex;
    gap: 20px;
`;

const CloseButton = styled.button`
    position: absolute;
    top: calc(0%);  
    right: calc(2.2%); 
    background: #FF5F1F;
    color: #FFF;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    cursor: pointer;
    font-size: 1.2rem;
    font-family: 'RetroFont', serif;

    &:hover {
        background: #FF7A3F;
    }
`;

const Sidebar = styled.div`
    width: 25%;
    background-color: #2D2A4A;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
`;

const Avatar = styled.img`
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid #FF5F1F;
`;

const Name = styled.h1`
    font-size: 1.8rem;
    color: #FF5F1F;
    align-items: center;
`;

const Role = styled.p`
    font-size: 1.2rem;
    text-align: left; 
    width: 100%; 
    margin-left: 0; 
    color: white;
`;

const ContactInfo = styled.div`
    width: 100%;
    text-align: left;
    margin-top: 20px;
`;

const ContactItem = styled.div`
    margin-bottom: 10px;
`;

const Label = styled.span`
    font-weight: bold;
    color: #FF5F1F;
`;

const Value = styled.span`
    display: block;
    color: white;
`;

const MainContent = styled.div`
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: auto;
`;

const Section = styled.div`
    background-color: #2D2A4A;
    border: 2px solid #FF5F1F;
    padding: 20px;
    border-radius: 10px;
`;

const SectionTitle = styled.h3`
    font-size: 1.5rem;
    color: #FF5F1F;
    margin: 0 0 10px;
`;

const AttributesGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    column-gap: 20px;
    row-gap: 15px;
`;

const Attribute = styled.div`
    display: grid;
    grid-template-columns: 35% 65%; 
    padding: 5px;
    border-bottom: 1px solid white;
`;

const AttributeName = styled.span`
    font-size: 1.1rem;
    font-weight: bold;
    color: #FF5F1F;
    white-space: nowrap; 
    width: 100px; 
`;

const AttributeValue = styled.span`
    font-size: 1.1rem;
    color: white;
    text-align: left;
    word-wrap: break-word; 
    line-height: 1.5;
`;

const AboutText = styled.p`
    font-size: 1.1rem;
    color: white;
    line-height: 1.5;
    margin-top: 10px;
`;

const SocialLinks = styled.div`
    display: flex;
    gap: 20px;
    margin-top: 10px;
`;

const SocialLink = styled.a`
    color: #FF5F1F;
    text-decoration: none;
    font-size: 1rem;
    transition: color 0.3s ease;

    &:hover {
        color: white;
    }
`;

const GithubIcon = styled.div`
    width: 35px;
    height: 35px;
    background-image: url(${require('../assets/images/github-default.png')});
    background-size: cover;
    transition: transform 0.3s ease, background-image 0.3s ease;
    &:hover {
        background-image: url(${require('../assets/images/github-hovered.png')});
        transform: scale(1.3);  /* Scale on hover */
    }
`;

const LinkedinIcon = styled.div`
    width: 35px;
    height: 35px;
    background-image: url(${require('../assets/images/linkedin-default.png')});
    background-size: cover;
    transition: transform 0.3s ease, background-image 0.3s ease;

    &:hover {
        background-image: url(${require('../assets/images/linkedin-hovered.png')});
        transform: scale(1.3);  /* Scale on hover */
    }
`;
