import React, { useState, useRef } from 'react';
import styled, { keyframes, css } from 'styled-components';
import {useNavigate} from "react-router-dom";

const projects = [
    {
        name: "Portfolio Website",
        description: "This website is an interactive portfolio website designed to showcase personal projects through a nostalgic, arcade-inspired interface. Built using React and styled-components, it features the aesthetic of vintage CRT machines with glitch effects, enhancing the retro experience. Users explore the portfolio by \"inserting cartridges\" into a simulated game system. The \"About\" section is styled as Dungeons & Dragons character sheet.",
        image: require('../assets/images/logo.png'),
    },
    {
        name: "map.it!",
        description: "map.it! is a web-based tool designed to assist future business owners in London by helping them identify the ideal location to launch their ventures. The platform features an interactive heatmap that visualises customer traffic patterns, rent prices, and accessibility to public transport across London. By integrating these key factors, the tool empowers small business owners to make well-informed, data-driven decisions when selecting the best location for their business.",
        image: require('../assets/images/mapit.png'),
        link: "https://github.com/phoonicked/ICHack2024",
        roles: [
            { label: "Role", description: "Modelled the Profitability Index using data from Google's API. Scraped data on  London's boroughs and the tube coordinates. Set up backend." },
            { label: "Tech Stack", description: "Python, Flask, React" },
            { label: "Achievements", description: "First place for Imperial's Newcomers category and runner-up for Citadel's Most Innovative Use of Financial Data." },
        ]
    },
    {
        name: "SpotLabs",
        description: (
            <>
                SpotLabs was an AI project built on the ICP (Internet Computer Protocol) blockchain, designed to promote the decentralization of large language models (LLMs). The goal was to equip organizations with a powerful and secure internal tool, utilizing decentralized AI technology for enhanced safety, efficiency, and scalability within enterprise environments. Try out the live project here:
                <a href="https://d4acv-saaaa-aaaal-qdlea-cai.icp0.io" target="_blank" rel="noopener noreferrer">https://d4acv-saaaa-aaaal-qdlea-cai.icp0.io</a>.
            </>
        ),
        image: require('../assets/images/spotlabs.png'),
        link: "https://github.com/phoonicked/SpotLabs",
        roles: [
            { label: "Role", description: "Engineered LLaMa using TypeScript to implement a custom function-calling method. Developed a JSON schema to facilitate 'Spot' interactions between the frontend and the LLM, and integrated the frontend with the LLM’s output." },
            { label: "Tech Stack", description: "TypeScript,  React" },
            { label: "Achievements", description: "First place for Decentralised AI with Internet Computer." },
        ]
    },
    {
        name: "CompBuilder",
        description: "CompBuilder was developed for 2022 November Computer Science IB (International Baccalaureate) IA (Internal Assessment). Built with Java, JavaFX, and MySQL, this desktop application helps users build computers by providing detailed information on components like RAM, CPU, and power supply, including prices and specifications. It simplifies the research process, making it easier to compare and select parts for custom builds.",
        image: require('../assets/images/compbuilder.png'),
    },
];

const Projects = () => {
    const [selectedCartridge, setSelectedCartridge] = useState(null); // Store the selected cartridge
    const [isInserted, setIsInserted] = useState(false); // Track if the cartridge is inserted
    const [fadeOutStarted, setFadeOutStarted] = useState(false);
    const [isSlottingOut, setIsSlottingOut] = useState(false);
    const [showStats, setShowStats] = useState(false);
    const [showWaitingMessage, setShowWaitingMessage] = useState(false);
    const [isInserting, setIsInserting] = useState(false);
    const readerRef = useRef(null);
    const navigate = useNavigate();


    const handleCartridgeClick = (index, cardRef) => {
        // Get the position of the reader and the card
        const readerBounds = readerRef.current.getBoundingClientRect();
        const cardBounds = cardRef.current.getBoundingClientRect();
        const readerCenterX = readerBounds.left + readerBounds.width / 2 - cardBounds.width / 2;
        const readerCenterY = readerBounds.top + readerBounds.height / 2 - cardBounds.height / 2;

        setSelectedCartridge({ index, readerCenterX, readerCenterY });
        setFadeOutStarted(true);
        setIsInserting(true);

        // Show the waiting message after fade-out
        setTimeout(() => {
            setShowWaitingMessage(true);
        }, 500);

        setTimeout(() => {
            setIsInserted(true);
            setShowWaitingMessage(false);  // Hide waiting message once slotted
            setIsInserting(false);
        }, 1500); // Adjust timing if needed
    };

    const handleUnslot = () => {
        setIsSlottingOut(true); // Start the slotting out process
        setIsInserted(false); // Close the cartridge
        setSelectedCartridge(null); // Reset selected cartridge
        setIsSlottingOut(false); // Re-enable selecting other cartridges
        setFadeOutStarted(false)
        setShowStats(false);
    };

    const handleShowStats = () => setShowStats(true);
    const handleCloseStats = () => setShowStats(false);

    const handleReturn = () => {
        navigate('/')
    }
    return (
        <ProjectsContainer>
            <SlotTitle>My Projects</SlotTitle>
            <CardsContainer>
                {projects.map((project, index) => {
                    const cardRef = React.createRef(); // Reference for each card
                    return (
                        <GameCard
                            key={index}
                            ref={cardRef}
                            selected={selectedCartridge?.index === index}
                            isInserted={isInserted}
                            fadeOutStarted={fadeOutStarted}
                            readerCenterX={selectedCartridge?.readerCenterX} // Pass the calculated X center position
                            readerCenterY={selectedCartridge?.readerCenterY} // Pass the calculated Y center position
                            onClick={() => !isInserted && !isSlottingOut && handleCartridgeClick(index, cardRef)} // Only trigger if not inserted
                        >
                            <CardTop>
                                <CardTitle>{project.name}</CardTitle>
                            </CardTop>
                            <CardBody>
                                <CardImage src={project.image} alt={project.name} />
                            </CardBody>
                            <Barcode />
                        </GameCard>
                    );
                })}
            </CardsContainer>
            <ReaderContainer ref={readerRef}>
                <Screen>
                    {isInserted ? (
                        <>
                            <InsertedMessage>{projects[selectedCartridge?.index]?.name}</InsertedMessage>
                            <ProjectDescription>
                                {projects[selectedCartridge?.index]?.description}
                            </ProjectDescription>
                        </>
                    ) : showWaitingMessage ? (
                        <WaitingMessage>Inserting Cartridge...</WaitingMessage>
                    ) : (
                        <InsertPrompt>Insert Cartridge</InsertPrompt>
                    )}
                </Screen>
                <ButtonGroup>
                    <DeviceButtons onClick={handleReturn}>Return</DeviceButtons>
                    <DeviceButtons onClick={handleUnslot} disabled={isInserting}>Unslot</DeviceButtons>

                    {/* Add the retro arcade button for the roles */}
                    {projects[selectedCartridge?.index]?.roles && (
                        <DeviceButtons onClick={handleShowStats}>
                            View Roles
                        </DeviceButtons>
                    )}

                    {/* Add the retro arcade button for the link */}
                    {projects[selectedCartridge?.index]?.link && (
                        <ArcadeLinkButton
                            href={projects[selectedCartridge?.index]?.link}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Visit Project
                        </ArcadeLinkButton>
                    )}
                </ButtonGroup>
            </ReaderContainer>

            {showStats && (
                <StatsOverlay onClick={handleCloseStats}>
                    <StatsModal onClick={(e) => e.stopPropagation()}>
                        {projects[selectedCartridge?.index]?.roles.map((stat, idx) => (
                            <StatItem key={idx}>
                                <StatLabel>{stat.label}:</StatLabel> {stat.description}
                            </StatItem>
                        ))}
                        <CloseButton onClick={handleCloseStats}>Close</CloseButton>
                    </StatsModal>
                </StatsOverlay>
            )}
        </ProjectsContainer>
    );
};

export default Projects;

// Keyframes for the animation
const moveToCenter = keyframes`
    0% {
        transform: translate(0, 0) scale(1);
    }
    100% {
        transform: translate(
                ${({ readerCenterX }) => readerCenterX}px,
                ${({ readerCenterY }) => readerCenterY}px
        ) scale(1);
    }
`;

const slotCartridge = keyframes`
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(52px); /* Adjust this value based on the height of the reader */
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
      visibility: hidden; /* Optional for making the faded-out cards non-clickable */
  }
`;

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

// Styled components
const ProjectsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: #1A1A1A;
  padding: 0;
  margin: 0;
`;

const SlotTitle = styled.h1`
  font-size: 2.5rem;
  color: #FF5F1F;
  margin-bottom: 20px;
  font-family: 'RetroFont', sans-serif;
`;

const CardsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

const GameCard = styled.div`
    background-color: #2D2A4A;
    width: 130px;
    height: 200px;
    border-radius: 5px;
    border: 10px solid #000000;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    position: ${({ selected }) => (selected ? "absolute" : "relative")}; /* Change to absolute when selected */
    cursor: pointer;
    transition: transform 0.3s ease, opacity 0.3s ease;

    /* Immediate fade out for other cards when a card is selected */
    ${({ fadeOutStarted, selected }) =>
    fadeOutStarted && !selected &&
    css`
            animation: ${fadeOut} 0.5s ease forwards;
        `}

        /* Add the fade-in effect when the slotting out is completed */
    ${({ isSlottingOut, fadeOutStarted }) =>
            !isSlottingOut && !fadeOutStarted &&
            css`
        animation: ${fadeIn} 0.5s ease forwards;
    `}


        /* Animation when selected */
    ${({ selected, readerCenterX, readerCenterY }) =>
    selected &&
    css`
            animation: ${moveToCenter} 0.7s ease forwards;
        `}

    /* Slotting animation */
    ${({ selected, isInserted }) =>
    selected &&
    isInserted &&
    css`
            animation: ${slotCartridge} 0.7s ease forwards;
        `}
`;

const CardTop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  background-color: #FF5F1F;
  border-radius: 5px 5px 0 0;
  color: white;
`;

const CardTitle = styled.h2`
  font-size: 0.8rem;
  text-align: center;
  font-family: 'RetroFont', sans-serif;
  margin: 0;
  padding: 5px;
`;

const CardBody = styled.div`
  width: 100%;
  height: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1A1836;
`;

const CardImage = styled.img`
  width: 120px;
  height: auto;
  border-radius: 5px;
`;

const Barcode = styled.div`
  width: 100%;
  height: 15px;
  background: repeating-linear-gradient(
    to right,
    #000,
    #000 2px,
    #FFF 2px,
    #FFF 4px
  );
  margin-top: 5px;
  border-radius: 0 0 5px 5px;
`;

const InsertedMessage = styled.h2`
    margin-top: -2px;
    font-size: 1.3rem;
    color: #FF5F1F;
    font-family: 'RetroFont', sans-serif;
    margin-bottom: 10px;
    text-align: center;
`;


const WaitingMessage = styled.h2`
  font-size: 1.5rem;
  color: #FF5F1F;
  font-family: 'RetroFont', sans-serif;
  animation: blink 1s infinite;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 10px;
    padding: 0 20px;

  @keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
  }
`;

const ReaderContainer = styled.div`
    background-color: #1a1a1a;
    width: 500px;
    height: 300px;  /* Increase height */
    border: 6px solid #333;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.6);
    position: relative;
    margin-top: 20px;
    padding: 20px;
    z-index: 999;

    &::before {
        content: '';
        position: absolute;
        top: -10px;
        left: -10px;
        width: calc(100% + 20px);
        height: calc(100% + 20px);
        border: 6px solid #222;
        border-radius: inherit;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.8);
    }
`;

// Screen area inside the reader
const Screen = styled.div`
    background-color: #111;
    width: 400px;
    height: 180px;  /* Increase height for better space */
    border: 4px solid #000;
    border-radius: 10px;
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: auto;  /* Enable vertical scrolling */
    padding: 10px;  /* Padding inside the screen */
    font-family: 'RetroFont', sans-serif;
    text-align: center;

    /* Ensure scrolling works */
    max-height: 180px;  /* Set max height for overflow */

    ::-webkit-scrollbar {
        width: 8px;  /* Width of the scrollbar */
    }

    ::-webkit-scrollbar-thumb {
        background-color: #FF5F1F;  /* Color of the scrollbar */
        border-radius: 10px;
    }

    ::-webkit-scrollbar-track {
        background-color: #333;  /* Background of the scrollbar track */
    }

    z-index: 10;  /* Ensure it is above any other element */
`;

const ProjectDescription = styled.p`
  font-size: 0.9rem;  /* Adjust the font size */
  color: white;
  margin: 0;
  padding: 0;
  line-height: 1.4;  /* Line height for readability */
  font-family: 'RetroFont', sans-serif;
  text-align: left;  /* Justify the text for better readability */
`;


const ButtonGroup = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px; /* Move buttons below the screen */
    gap: 10px;
    z-index: 1000;
`;

const DeviceButtons = styled.button`
    background-color: #444;
    color: white;
    border: 2px solid #222;
    border-radius: 10px;
    padding: 7px 20px;
    margin: 10px 0;
    font-family: 'RetroFont', sans-serif;
    cursor: pointer;
    transition: background-color 0.2s ease, transform 0.1s ease;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);

    &:hover {
        background-color: #666;
        transform: translateY(-2px);
    }
`;

const InsertPrompt = styled.h2`
  font-size: 1.5rem;
  color: #FF5F1F;
  font-family: 'RetroFont', sans-serif;
`;

const ArcadeLinkButton = styled.a`
    background-color: #FF5F1F;
    color: white;
    border: 2px solid #222;
    border-radius: 10px;
    padding: 7px 20px;
    font-family: 'RetroFont', sans-serif;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.2s ease, transform 0.1s ease;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
    margin-left: 10px; /* Space between buttons */

    &:hover {
        background-color: #D94D1A;
        transform: translateY(-2px);
    }

    &:active {
        transform: translateY(0); /* Slight press effect */
    }

    &::before {
        content: '► ';
    }
`;

const StatsOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const StatsModal = styled.div`
  background-color: #1A1A1A;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  text-align: center;
  border: 2px solid #FF5F1F;
  font-family: 'RetroFont', sans-serif;
`;

const StatItem = styled.div`
  margin-bottom: 10px;
  color: #FFF;
  text-align: left;
`;

const StatLabel = styled.span`
  font-weight: bold;
  color: #FF5F1F;
`;

const CloseButton = styled.button`
  background-color: #FF5F1F;
  color: white;
  padding: 10px 20px;
  margin-top: 20px;
  border: none;
  cursor: pointer;
  font-family: 'RetroFont', sans-serif;
  
  &:hover {
    background-color: #D94D1A;
  }
`;
