import React, { useState, useEffect, useRef } from 'react';
import styled, { createGlobalStyle, keyframes, css } from 'styled-components';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import TypeIt from 'typeit-react';
import About from './components/About';
import Resume from './components/Resume';
import Recommendations from './components/Recommendations';
import Projects from './components/Projects'
import ContactForm from "./components/ContactForm";
import logo from './assets/images/logo.png';

const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden; 
    background-color: black; 
  }

  #root {
    height: 100%;
  }
`;

const slideUp = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
`;

const slideDown = keyframes`
  from {
    transform: translateY(-150%);
  }
  to {
    transform: translateY(0);
  }
`;

const openCharacterSheet = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
`;

const App = () => {
    const [startClicked, setStartClicked] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [showCharacterSheet, setShowCharacterSheet] = useState(false);
    const [hasAnimated, setHasAnimated] = useState(false);
    const [animationsEnabled, setAnimationsEnabled] = useState(true);
    const [showPopup, setShowPopup] = useState(true);
    const [showSecondaryNavbar, setShowSecondaryNavbar] = useState(false);
    const mainContainerRef = useRef(null);
    const navigate = useNavigate();

    const menuItems = ["About", "Resume", "Projects", "Contact", animationsEnabled ? "Disable Animations" : "Enable Animations"];

    useEffect(() => {
        const savedPreference = localStorage.getItem('animationsEnabled');
        if (savedPreference !== null) {
            setAnimationsEnabled(JSON.parse(savedPreference));
        }
    }, []);

    useEffect(() => {
        if (startClicked) {
            const timer = setTimeout(() => {
                setShowSecondaryNavbar(true);
            }, 15);
            return () => clearTimeout(timer);
        }
    }, [startClicked]);

    const handleAnimationComplete = () => {
        setHasAnimated(true);
        sessionStorage.setItem('hasAnimated', 'true');
    };

    const handleKeepAnimation = () => {
        setAnimationsEnabled(true);
        localStorage.setItem('animationsEnabled', true);
        setShowPopup(false);
    };

    const handleDisableAnimation = () => {
        setAnimationsEnabled(false);
        localStorage.setItem('animationsEnabled', false);
        setShowPopup(false);
    };

    const handleNavbarClick = (path) => {
        setStartClicked(true);

        if(path === "Enable Animations"){
            setAnimationsEnabled(true);
        }
        else if(path === "Disable Animations"){
            setAnimationsEnabled(false);
        }
        else if (path === "Projects") {
            setTimeout(() => {
                setShowPopup(false);
                navigate('/projects');
            }, 300); // Delay for better wallet opening effect
        }
        else if(path === "About"){
            setShowCharacterSheet(true);
            setShowPopup(false);
            navigate('/about');
        }
        else {
            setShowPopup(false);
            const lowerCasePath = path.toLowerCase();
            navigate(lowerCasePath);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'ArrowDown') {
            setSelectedIndex((prevIndex) => (prevIndex + 1) % menuItems.length);
        } else if (e.key === 'ArrowUp') {
            setSelectedIndex((prevIndex) => (prevIndex - 1 + menuItems.length) % menuItems.length);
        }
        if (e.key === 'Enter') {
            handleNavbarClick(menuItems[selectedIndex]);
        }
    };


    return (
        <>
            <GlobalStyle />
            <MainContainer
                ref={mainContainerRef}
                onKeyDown={handleKeyDown}
                tabIndex="0"
                showPopup={showPopup}
            >
                {showPopup && (
                    <Popup>
                        <PopupTitle>Warning!</PopupTitle>
                        <PopupText>This site contains flashing animations designed to simulate a CRT effect. Would you like to disable these animations? You can also enable/disable them manually in the main menu.</PopupText>
                        <ButtonGroup>
                            <PopupButton onClick={handleKeepAnimation}>Keep Animation</PopupButton>
                            <PopupButton onClick={handleDisableAnimation}>Disable Animation</PopupButton>
                        </ButtonGroup>
                    </Popup>
                )}

                <StyledNavbar startClicked={startClicked}>
                    <Navbar handleNavbarClick={handleNavbarClick} />
                </StyledNavbar>

                {startClicked && showSecondaryNavbar && (
                    <SecondaryNavbar>
                        <Logo src={logo} alt="Phoonicked Logo" />
                        <BrandName>PHOONICKED</BrandName>
                    </SecondaryNavbar>
                )}

                {!startClicked ? (
                    <RoundedScreen animationsEnabled={animationsEnabled} showPopup={showPopup}>
                        <Content onClick={() => setStartClicked(true)}>
                            <StartText>Press Any Key to Start</StartText>
                        </Content>
                    </RoundedScreen>
                ) : (
                    <RoundedScreen animationsEnabled={animationsEnabled}>
                        <Routes>
                            <Route path="/" element={
                                <Content>
                                    <Title>
                                        {!hasAnimated && (
                                            <TypeIt
                                                options={{
                                                    strings: ["Hello, I'm Wilbert!", "Welcome to my portfolio website."],
                                                    speed: 40,
                                                    waitUntilVisible: true,
                                                    breakLines: true,
                                                    afterComplete: handleAnimationComplete
                                                }}
                                            />
                                        )}
                                        {hasAnimated && (
                                            <span>Hello, I'm Wilbert! <br /> Welcome to my portfolio website.</span>
                                        )}
                                    </Title>
                                    <Menu>
                                        {menuItems.map((item, index) => (
                                            <MenuItem
                                                key={index}
                                                onClick={() => handleNavbarClick(item)}
                                                className={selectedIndex === index ? 'selected' : ''}
                                                onMouseEnter={() => setSelectedIndex(index)}
                                            >
                                                {selectedIndex === index && <Arrow>&gt;</Arrow>}
                                                <MenuText className={selectedIndex === index ? 'blinking' : ''}>
                                                    {item}
                                                </MenuText>
                                                {selectedIndex === index && <Arrow>&lt;</Arrow>}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </Content>
                            } />
                            <Route path="/about" element={
                                <CharacterSheetContainer show={showCharacterSheet}>
                                    <About />
                                </CharacterSheetContainer>
                            } />
                            <Route path="/resume" element={<Resume />} />
                            <Route path="/recommendations" element={<Recommendations />} />
                            <Route path="/projects" element={<Projects />} />
                            <Route path="/contact" element={<ContactForm />} />
                        </Routes>
                    </RoundedScreen>
                )}
            </MainContainer>
        </>
    );
};

export default App;

const MainContainer = styled.div`
  background-color: black;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  outline: none;

    /* Disable interactions when popup is visible */
    ${({ showPopup }) => showPopup && `
    pointer-events: none;
  `}

        /* Enable interactions for the popup itself */
    & > div:nth-child(1) {
        pointer-events: all;
    }


    /* Mobile and small devices */
  @media (max-width: 600px) {
    justify-content: flex-start;
    padding-top: 20px;
  }
`;

const StartText = styled.h1`
  font-size: 2.5rem;
  color: #ff5f1f;
  font-family: 'RetroFont', sans-serif;
  animation: blink 1s infinite;

  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media (max-width: 480px) {
    font-size: 2rem; 
  }
`;

const RoundedScreen = styled.div`
  background-color: #2d2a4a;
  border-radius: 30px;
  margin-top: 5%;
  width: 80vw;
  height: 75vh;
  box-shadow: inset 0 -30px 80px rgba(0, 0, 0, 0.8),
    inset 0 30px 80px rgba(0, 0, 0, 0.3), 0 0 50px rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 10px solid #1a1836;
  position: relative;
  overflow: hidden;

  /* Simulating the curvature of a CRT screen */
  transform: scale(1.02) perspective(400px) rotateX(3deg);

  /* Combine CRT glow and glitch in one before element */
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.05) 20%, transparent 80%),
                linear-gradient(
                  to bottom,
                  rgba(255, 0, 0, 0.05),
                  rgba(0, 255, 255, 0.1),
                  rgba(0, 0, 255, 0.08)
                );
    z-index: 1;
    pointer-events: none;
    mix-blend-mode: multiply;

    /* Glitch Effect with condition for animationsEnabled */
    ${({ animationsEnabled, showPopup }) => animationsEnabled && !showPopup && css`
      animation: glitchEffect 0.5s infinite;
    `}
  }

  /* Scanlines */
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: repeating-linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.2) 1px,
      rgba(255, 255, 255, 0) 2px
    );
    z-index: 2;
    pointer-events: none;
    mix-blend-mode: multiply;
  }

  /* Flicker effect with random intensity */
  ${({ animationsEnabled, showPopup }) => animationsEnabled && !showPopup && css` 
    animation: crtFlicker 0.1s infinite steps(1) alternate,
               crtPulse 2s infinite ease-in-out;
  `};;

  @keyframes crtFlicker {
    0% { opacity: 0.98; }
    50% { opacity: 1; }
    100% { opacity: 0.95; }
  }

  /* Brightness pulse to simulate the uneven brightness of a CRT */
  @keyframes crtPulse {
    0%, 100% { filter: brightness(1); }
    50% { filter: brightness(1.1); }
  }

  @keyframes glitchEffect {
    0%, 100% {
      clip-path: inset(0 0 0 0);
      transform: none;
    }
    10% {
      clip-path: inset(10% 0 85% 0);
      transform: translate(-20px, -10px);
    }
    20% {
      clip-path: inset(25% 0 60% 0);
      transform: translate(20px, 10px);
    }
    30% {
      clip-path: inset(40% 0 35% 0);
      transform: translate(-30px, 5px);
    }
    40% {
      clip-path: inset(50% 0 25% 0);
      transform: translate(10px, -5px);
    }
    50% {
      clip-path: inset(10% 0 85% 0);
      transform: translate(5px, -30px);
    }
    60% {
      clip-path: inset(25% 0 60% 0);
      transform: translate(30px, 5px);
    }
    70% {
      clip-path: inset(40% 0 35% 0);
      transform: translate(-20px, 10px);
    }
    80% {
      clip-path: inset(50% 0 25% 0);
      transform: translate(20px, -10px);
    }
    90% {
      clip-path: inset(5% 0 90% 0);
      transform: translate(-25px, 20px);
    }
  }

  @media (max-width: 1024px) {
    width: 90vw;
    height: 65vh;
    border-radius: 20px;
  }

  @media (max-width: 768px) {
    width: 95vw;
    height: 60vh;
  }

  @media (max-width: 480px) {
    width: 98vw;
    height: 55vh;
    border-radius: 15px;
    transform: scale(1.01);
  }
`;

const CharacterSheetContainer = styled.div`
    width: 75vw;
    height: 75vh;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: ${({ show }) => (show ? openCharacterSheet : 'none')} 0.7s ease-out;

    /* For tablet screens */
    @media (max-width: 1024px) {
        width: 85vw;
        height: 70vh;
    }

    /* For mobile screens */
    @media (max-width: 768px) {
        width: 90vw;
        height: 65vh;
    }

    /* For smaller mobile devices */
    @media (max-width: 480px) {
        width: 95vw;
        height: 60vh;
    }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100vw;
  height: 100vh;

  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;

const Title = styled.h1`
  font-size: 2.5rem;
  line-height: 1.2;
  font-family: 'RetroFont', sans-serif;

  @media (max-width: 1024px) {
    font-size: 2rem;
  }

  @media (max-width: 768px) {
    font-size: 1.8rem;
  }

  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

const Menu = styled.div`
  margin-top: 40px;

  @media (max-width: 768px) {
    margin-top: 30px;
  }
`;

const MenuItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FF5F1F;
  font-size: 2rem;
  font-family: 'RetroFont', sans-serif;
  text-decoration: none;
  margin: 10px 0;
  cursor: pointer;

  @media (max-width: 1024px) {
    font-size: 1.8rem;
  }

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }

  @media (max-width: 480px) {
    font-size: 1.2rem;
  }
`;

const MenuText = styled.span`
  display: inline-block;

  &.blinking {
    animation: blink 1s infinite;
  }

  @keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
  }
`;

const Arrow = styled.span`
  color: #FF5F1F; 
  margin: 0 10px;
  font-size: 2rem;
`;

const StyledNavbar = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  animation: ${({ startClicked }) => (startClicked ? slideUp : 'none')} 1.2s ease-in-out forwards;

  @media (max-width: 768px) {
    position: fixed;
    bottom: 0;
  }
`;

const SecondaryNavbar = styled.div`
    position: fixed;
    font-size: 2.5rem;
    top: 0;
    left: 0;
    z-index: 999; 
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
    background: linear-gradient(90deg, #1A1836 0%, #3C3A63 50%, #1A1836 100%);
    border-bottom: 4px solid;
    border-image: linear-gradient(90deg, #B0BEC5, #CFD8DC, #B0BEC5) 1;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    animation: ${slideDown} 1.7s ease-in-out;
    height: 90px;

  @media (max-width: 768px) {
    height: 70px;
    padding: 5px 15px;
  }
`;

const Logo = styled.img`
  height: 70px;
  margin-right: 10px;

  @media (max-width: 768px) {
    height: 50px;
  }
`;

const BrandName = styled.span`
  color: white;
  font-family: 'RetroFont', sans-serif;
  font-size: 2.5rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Popup = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0); /* Start scaled down */
    background-color: #1A1836;
    padding: 30px;
    border: 5px solid #FF5F1F;
    width: 600px;
    text-align: center;
    z-index: 1000;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.7);
    font-family: 'RetroFont', sans-serif;
    animation: popupAnimation 1s ease forwards, bounce 1.5s ease-in-out infinite; /* Animate with scale and bounce */

  @media (max-width: 768px) {
    width: 80%;
    padding: 20px;
  }

  @keyframes popupAnimation {
    0% {
      transform: translate(-50%, -50%) scale(0);
    }
    50% {
      transform: translate(-50%, -50%) scale(1.2);
    }
    100% {
      transform: translate(-50%, -50%) scale(1);
    }
  }

  @keyframes bounce {
    0%,
    100% {
      transform: translate(-50%, -50%) scale(1);
    }
    50% {
      transform: translate(-50%, calc(-50% - 5px)) scale(1.02);
    }
  }
`;

const PopupTitle = styled.h2`
  color: #FF5F1F;
  font-size: 1.8rem;
  margin-bottom: 20px;
`;

const PopupText = styled.p`
  color: white;
  font-size: 1.2rem;
  margin-bottom: 20px;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PopupButton = styled.button`
  background-color: #FF5F1F;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  font-family: 'RetroFont', sans-serif;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #D94D1A;
  }
`;