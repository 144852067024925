import React, { useEffect, useState, useRef } from 'react';
import styled, { createGlobalStyle, keyframes, css } from 'styled-components';
import { useNavigate } from 'react-router-dom';

const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    font-family: 'RetroFont', sans-serif;
  }

  #root {
    height: 100%;
  }
`;

const blink = keyframes`
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
`;

const Recommendations = () => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const navigate = useNavigate();
    const containerRef = useRef(null);

    const letters = [
        { title: "Recommendation Letter", company: "Blibli.com", pdf: "recommendation-blibli.pdf" }
    ];

    useEffect(() => {
        const handleFocus = () => {
            if (containerRef.current) {
                containerRef.current.focus();
            }
        };

        window.addEventListener('keydown', handleFocus);
        return () => window.removeEventListener('keydown', handleFocus);
    }, []);

    const handleKeyDown = (e) => {
        if (e.key === 'ArrowDown') {
            setSelectedIndex((prevIndex) => (prevIndex + 1) % (letters.length + 1));
        } else if (e.key === 'ArrowUp') {
            setSelectedIndex((prevIndex) => (prevIndex - 1 + letters.length + 1) % (letters.length + 1));
        } else if (e.key === 'Enter') {
            if (selectedIndex === 0) {
                showOptions();
            } else {
                navigate(-1);
            }
        }
    };

    const showOptions = () => {
        const downloadLink = require(`../assets/${letters[selectedIndex].pdf}`);
        const userAction = window.confirm(
            "Do you want to download the file? Click 'OK' to download, 'Cancel' to preview."
        );

        if (userAction) {
            const link = document.createElement('a');
            link.href = downloadLink;
            link.download = letters[selectedIndex].pdf;
            link.click();
        } else {
            window.open(downloadLink, '_blank');
        }
    };

    return (
        <>
            <GlobalStyle />
            <Container
                ref={containerRef}
                onKeyDown={handleKeyDown}
                tabIndex="0"
            >
                <Header>RECOMMENDATIONS</Header>
                <Menu>
                    <MenuItem
                        selected={selectedIndex === 0}
                        onMouseEnter={() => setSelectedIndex(0)}
                        onClick={showOptions}
                    >
                        {selectedIndex === 0 && <Arrow>&gt;</Arrow>}
                        <Title blinking={selectedIndex === 0}>{letters[0].title}</Title>
                        <CompanyName>{letters[0].company}</CompanyName>
                        {selectedIndex === 0}
                    </MenuItem>
                    <MenuItem
                        selected={selectedIndex === 1}
                        onMouseEnter={() => setSelectedIndex(1)}
                        onClick={() => navigate(-1)}
                    >
                        {selectedIndex === 1 && <Arrow>&gt;</Arrow>}
                        <Title blinking={selectedIndex === 1}>BACK TO RESUME</Title>
                        {selectedIndex === 1}
                    </MenuItem>
                </Menu>
            </Container>
        </>
    );
};

export default Recommendations;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    color: #FFD700;
    outline: none;
`;

const Header = styled.h1`
    color: #FFD700;
    font-size: 2.5rem;
    margin-bottom: 50px;
`;

const Menu = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 1.5rem;
`;

const MenuItem = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${({ selected }) => (selected ? '#FF5F1F' : '#FFD700')};
    margin: 10px 0;
    cursor: pointer;
    transition: color 0.3s ease;

    &:hover {
        color: #FF5F1F;
    }
`;

const Title = styled.span`
    color: #FFD700;
    margin-right: 20px;
    ${({ blinking }) => blinking && css`animation: ${blink} 1s infinite alternate;`}
`;

const CompanyName = styled.span`
    color: #00FFFF;  
`;

const Arrow = styled.span`
    color: #FF5F1F;
    font-size: 1.5rem;
    position: absolute;
    margin-left: -30px ;
`;
