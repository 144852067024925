import React, { useState, useEffect, useRef } from 'react';
import styled, { createGlobalStyle, keyframes, css } from "styled-components";
import { useNavigate } from 'react-router-dom';

const GlobalStyle = createGlobalStyle`
    html, body {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-color: black;
    }

    #root {
        height: 100%;
    }
`;

const fillMeter = keyframes`
    to {
        opacity: 1;
    }
`;

const blink = keyframes`
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
`;

const Resume = () => {
    const [pdfLoaded, setPdfLoaded] = useState(false);
    const [loading, setLoading] = useState(true);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const navigate = useNavigate();
    const containerRef = useRef(null);

    const menuItems = ["Exit", "Recommendations"];

    useEffect(() => {
        const handleFocus = () => {
            if (containerRef.current) {
                containerRef.current.focus();
            }
        };

        window.addEventListener('keydown', handleFocus);
        return () => window.removeEventListener('keydown', handleFocus);
    }, []);

    const handleKeyDown = (e) => {
        e.preventDefault();
        if (e.key === 'ArrowLeft') {
            setSelectedIndex((prevIndex) => (prevIndex - 1 + menuItems.length) % menuItems.length);
        } else if (e.key === 'ArrowRight') {
            setSelectedIndex((prevIndex) => (prevIndex + 1) % menuItems.length);
        } else if (e.key === 'Enter') {
            if (selectedIndex === 0) {
                navigate('/');
            } else if (selectedIndex === 1) {
                requestAnimationFrame(() => navigate('/recommendations'));
            }
        }
        if (containerRef.current) {
            containerRef.current.focus();
        }
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            fetch(require('../assets/resume.pdf'))
                .then(response => response.blob())
                .then(blob => {
                    const url = URL.createObjectURL(blob);
                    setPdfLoaded(url);
                    setLoading(false);
                });
        }, 4000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            <GlobalStyle />
            <ScreenContainer
                ref={containerRef}
                onKeyDown={handleKeyDown}
                tabIndex="0"
            >
                {loading ? (
                    <LoadingContainer>
                        <LoadingText>Loading...</LoadingText>
                        <LoaderBar>
                            <BlockBorder />
                            <BlockBorder />
                            <BlockBorder />
                            <BlockBorder />
                            <BlockBorder />
                            <BlockBorder />
                            <BlockBorder />
                            <BlockBorder />
                            <BlockMeter />
                            <BlockMeter />
                            <BlockMeter />
                            <BlockMeter />
                            <BlockMeter />
                            <BlockMeter />
                            <BlockMeter />
                            <BlockMeter />
                            <BlockMeter />
                            <BlockMeter />
                        </LoaderBar>
                    </LoadingContainer>
                ) : (
                    <ResumeWrapper>
                        <ResumeSection>
                            <PDFViewer src={pdfLoaded} />
                        </ResumeSection>
                        <Menu>
                            {menuItems.map((item, index) => (
                                <MenuItem
                                    key={index}
                                    selected={index === selectedIndex}
                                    onMouseEnter={() => setSelectedIndex(index)}
                                    onClick={() => {
                                        if (index === 0) navigate('/');
                                        else navigate('/recommendations');
                                    }}
                                >
                                    {index === selectedIndex && <Arrow>&gt;</Arrow>}
                                    <MenuText className={selectedIndex === index ? 'blinking' : ''}>{item}</MenuText>
                                </MenuItem>
                            ))}
                        </Menu>
                    </ResumeWrapper>
                )}
            </ScreenContainer>
        </>
    );
};

export default Resume;

const ScreenContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: radial-gradient(circle, #2D2A4A, #1A1A1A);
    position: relative;
    outline: none;
`;

const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
`;

const LoaderBar = styled.div`
    width: 30vw;
    height: 6vw;
    display: grid;
    grid-template-columns: repeat(3, 1vw) repeat(10, 1.5vw 1vw) repeat(2, 1vw);
    grid-template-rows: repeat(6, 1vw);
`;

const BlockBorder = styled.div`
    background-color: #FFD700;
    &:nth-of-type(1) { grid-row: 1; grid-column: 3/-3; }
    &:nth-of-type(2) { grid-row: -2; grid-column: 3/-3; }
    &:nth-of-type(3) { grid-row: 3/5; grid-column: 1; }
    &:nth-of-type(4) { grid-row: 3/5; grid-column: -2; }
    &:nth-of-type(5) { grid-row: 2; grid-column: 2; }
    &:nth-of-type(6) { grid-row: 2; grid-column: -3; }
    &:nth-of-type(7) { grid-row: 5; grid-column: 2; }
    &:nth-of-type(8) { grid-row: 5; grid-column: -3; }
`;

const BlockMeter = styled.div`
    background-color: #00FF00;
    opacity: 0;
    animation: ${fillMeter} 0.5s ease-in-out forwards;
    &:nth-of-type(9) { grid-row: 3/5; grid-column: 4; animation-delay: 0.3s }
    &:nth-of-type(10) { grid-row: 3/5; grid-column: 6; animation-delay: 0.7s }
    &:nth-of-type(11) { grid-row: 3/5; grid-column: 8; animation-delay: 1s}
    &:nth-of-type(12) { grid-row: 3/5; grid-column: 10; animation-delay: 1.2s }
    &:nth-of-type(13) { grid-row: 3/5; grid-column: 12; animation-delay: 1.3s }
    &:nth-of-type(14) { grid-row: 3/5; grid-column: 14; animation-delay: 1.6s }
    &:nth-of-type(15) { grid-row: 3/5; grid-column: 16; animation-delay: 2s }
    &:nth-of-type(16) { grid-row: 3/5; grid-column: 18; animation-delay: 2.1s }
    &:nth-of-type(17) { grid-row: 3/5; grid-column: 20; animation-delay: 2.4s }
    &:nth-of-type(18) { grid-row: 3/5; grid-column: 22; animation-delay: 2.6s }
`;

const LoadingText = styled.div`
    font-size: 2rem;
    color: #FFD700;
    font-family: 'RetroFont', sans-serif;
    margin-bottom: 10px;
    text-align: center;
`;

const ResumeWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    height: 95%;
`;

const ResumeSection = styled.div`
    width: 100%;
    height: 85%;
    background: #1A1A1A;
    border: 10px solid #FFD700;
    border-radius: 10px;
    box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.7), 0 0 40px rgba(0, 0, 0, 0.8);
`;

const PDFViewer = styled.iframe`
    width: 100%;
    height: 100%;
    border: none;
    background-color: #3E2723;
    box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.5);
`;

const Menu = styled.div`
    margin-top: 20px;
    display: flex;
    justify-content: center;
    width: 100%;
`;

const MenuItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ selected }) => (selected ? '#FF5F1F' : '#FFD700')};
    font-size: 1.5rem;
    font-family: 'RetroFont', sans-serif;
    text-decoration: none;
    margin: 20px 20px;
    cursor: pointer;
    position: relative;
    padding: 10px 30px;
    transition: color 0.3s ease;
`;

const MenuText = styled.span`
    color: #FF5F1F;
    margin-right: 20px;
    ${({ blinking }) => blinking && css`
        animation: ${blink} 1s infinite alternate;
    `}

    &.blinking {
        animation: blink 1s infinite;
    }

    @keyframes blink {
        0% { opacity: 1; }
        50% { opacity: 0; }
        100% { opacity: 1; }
    }
`;

const Arrow = styled.span`
    position: absolute;
    left: -0;
    color: #FF5F1F;
    font-size: 1.3rem;  
`;